import { GetDeliverArgs, GetDeliverList } from "@/api/LogisticApi";
import { ShopDownloadDeliveryList } from '@/api/DownloadApi';
import { LogisticStatus, OrderType, ProductType, sortDirection } from "@/common/enum";
import { FormatterDateTime, TranslateLogisticStatus } from "@/common/formatter";
import MerchantService from "@/services/Merchant/MerchantService";
import { reactive, ref } from "vue";
import TableSortClick from "@/views/Common/SortTable";
const { setCurrentSort, headerSortClick } = TableSortClick();
import { ScrollToTop } from "@/common/utils";
setCurrentSort({
    prop: "purchaseTime",
});
export const datas = reactive(new Array());
export const totalCount = ref(0);
export const pageSize = ref(20);
export const page = ref(1);
export const loading = ref(false);
export const dialogTitle = ref('');
export const dealTitle = ref('');
export const dealRemark = ref('');
export const dealDeliverId = ref(0);
export const headerPurchaseTime = ref('');
export const headerDeliverTime = ref('');
const spans = ref(new Array());
export const orderInfo = reactive({
    productName: "",
    expressName: "",
    expressNo: "",
    deliverId: 0,
});
export const queryArgs = reactive(new GetDeliverArgs());
queryArgs.orderId = '';
queryArgs.productNo = "";
queryArgs.page = 1;
queryArgs.pageSize = 10;
queryArgs.productName = "";
queryArgs.recipientName = "";
queryArgs.recipientPhone = "";
queryArgs.status = LogisticStatus.AllSended;
queryArgs.purchaseTimeStart = ""; //购买开始时间
queryArgs.purchaseTimeEnd = ""; //购买结束时间
queryArgs.deliverTimeStart = ""; //发货开始时间
queryArgs.deliverTimeEnd = ""; //发货结束时间
queryArgs.sortDirection = 0;
queryArgs.orderType = OrderType.PurchaseTime;
queryArgs.productType = ProductType.All;
const calcSpans = (list) => {
    list = list.map((item) => item.orderId);
    let proOrderId = 0;
    let len = 0;
    let rowIndex = 0;
    list.forEach((item, index) => {
        if (index == 0) {
            proOrderId = item;
            len = 1;
            rowIndex = 0;
        }
        else if (proOrderId == item) {
            len++;
        }
        else {
            spans.value.push({
                rowIndex,
                rowSpan: len
            });
            rowIndex = index;
            proOrderId = item;
            len = 1;
        }
        if (index === (list.length - 1)) {
            spans.value.push({
                rowIndex,
                rowSpan: len
            });
        }
    });
};
export const objectSpanMethod = ({ row, column, rowIndex, columnIndex, }) => {
    const span = spans.value.find(item => item.rowIndex == rowIndex);
    if (columnIndex == 0 || columnIndex == 8) {
        if (span) {
            return {
                rowspan: span.rowSpan,
                colspan: 1,
            };
        }
        else {
            return {
                rowspan: 0,
                colspan: 0,
            };
        }
    }
};
export const toCompanySearch = (expressName, expressNo) => {
    window.open(`https://www.so.com/s?q=${expressName}%20${expressNo}`);
};
export async function queryData() {
    MerchantService.ShopDetailsAsync().then(async () => {
        queryArgs.merchantId = MerchantService.GetMerchantDetail().id;
        loading.value = true;
        const res = await GetDeliverList({
            ...queryArgs,
            orderId: +(queryArgs.orderId || '')
        });
        loading.value = false;
        if (res.errno == 0) {
            datas.splice(0, datas.length);
            const list = res.data.list || [];
            spans.value = [];
            calcSpans(list);
            list.forEach(item => {
                item.showMore = false;
                datas.push(item);
            });
            totalCount.value = res.data.total;
        }
        ScrollToTop();
    });
}
export function formatAfterSalesStatus(row, col, cell) {
    switch (cell) {
        case 0:
            return '无售后';
        case 1:
            return '售后中';
        default:
            return '售后完成';
    }
}
export function formatPurchaseTime(row) {
    return FormatterDateTime(row.purchaseTime, "yyyy-MM-dd hh:mm:ss");
}
export function formatLogisticStatus(row) {
    return TranslateLogisticStatus(row.deliverStatus || LogisticStatus.All);
}
export function formatDeliverTime(row) {
    return FormatterDateTime(row.deliverTime, "yyyy-MM-dd hh:mm:ss");
}
export const headerClick = async (column) => {
    if (await headerSortClick(column)) {
        queryArgs.orderType = column.prop == 'purchaseTime' ? OrderType.PurchaseTime : OrderType.SendTime;
        queryArgs.sortDirection = sortDirection[column.order] * 1;
        queryArgs.page = 1;
        queryData();
    }
};
export const changeTime = (val, type) => {
    if (type == 0) {
        queryArgs.purchaseTimeStart = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || "";
        queryArgs.purchaseTimeEnd = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || "";
    }
    else {
        queryArgs.deliverTimeStart = val && FormatterDateTime(val[0], "yyyy-MM-dd hh:mm:ss") || "";
        queryArgs.deliverTimeEnd = val && FormatterDateTime(val[1], "yyyy-MM-dd hh:mm:ss") || "";
    }
};
export const showDownloadDialog = ref(false);
export const expDeliver = () => {
    ShopDownloadDeliveryList({
        ...queryArgs,
        orderId: 0,
        page: 0,
        pageSize: 0
    }, '已发货商品列表.xlsx');
};
export const showDlg = ref(false);
export const editShippingInfo = (row) => {
    showDlg.value = true;
    orderInfo.expressName = row.expressName || '';
    orderInfo.expressNo = row.expressNo || '';
    orderInfo.productName = row.productName || '';
    orderInfo.deliverId = row.deliverId || 0;
    dialogTitle.value = '修改发货单号（订单号' + row.deliverOrder + '）';
};
export const dealDlg = ref(false);
export const editDeal = (row) => {
    dealTitle.value = row.deliverRemark?.length ? '修改处理说明' : '添加处理说明';
    dealRemark.value = row.deliverRemark || '',
        dealDeliverId.value = row.deliverId || 0;
    dealDlg.value = true;
};
